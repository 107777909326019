export const SET_NAME = (state, name) =>{
    state.name = name;
}

// export const SET_FULLNAME = (state, fullname) => {    
//     state.fullname = fullname;
// }

// export const SET_LASTNAME = (state,lastname) => {    
//     state.lastname = lastname;
// }

// export const SET_DATEBIRTH = (state, datebirth) =>{
//     state.datebirth = datebirth;
// }

// export const SET_EMAIL = (state, email) => {
//     state.email = email;
// }

// export const SET_STATUSUSER = (state,statususer) =>{
//     state.statususer = statususer;
// }

export const SET_STATUS_REQUEST_LOGIN = (state,status) =>{
    state.status_request_login = status;
} 